/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useMemo } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useRecoilState } from "recoil";
import { dashboardOptionState } from "recoil/dashboardOptionState";
import {
  LayoutFlexCenterAligned,
  MontserratTypography,
  NotoSansTypography,
} from "@common/components";
import DataNotFound from "components/ui/DataNotFound";
import { COLORS, FONTS } from "@common/styles";
import Pagination from "components/ui/Pagination";
import {
  getConciergeOrdersQuery,
  UpdateConciergeOrderMutation,
} from "graphql/order";
import {
  useGetAccommodationId,
  usePagination,
  usePaginationInitialConfig,
} from "helpers/hooks";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { UseInputState } from "types/hooks";
import { ConciergeOrderStatistics } from "types/statistics";
import { getConciergeOrderStatisticsQuery } from "graphql/statistics";
import { POLL_INTERVAL } from "helpers/constants";
import {
  ConciergeOrder,
  ConciergeOrderEdge,
  ConciergeOrderStatus,
} from "types/order";
import { DashboardType, SearchOptions } from "types/dashboard";
import DashboardItem from "../components/Item";
import DashboardFilter from "./Filter";
import DashboardOrderModal from "./modal/Order";
import DashboardOrderReject from "./modal/OrderReject";

interface PricingProps {
  title: string;
  value: number;
  unit: string;
}

interface Modal {
  type: "order" | "orderReject" | "";
  isOpened: boolean;
  data: ConciergeOrder | null;
}

const Wrapper = styled.div`
  padding: 40px 60px;
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
`;

const PricingWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  justify-content: space-between;
`;

const Grid = styled.div`
  display: flex;
  width: 25%;

  ${({ isLast }: { isLast?: boolean }) =>
    !isLast &&
    css`
      ::after {
        content: "";
        width: 1px;
        height: 100%;
        background-color: ${COLORS.GRAY_02};
      }
    `}
`;

const PricingGrid = styled(LayoutFlexCenterAligned)`
  width: 244px;
`;

const Pricing: React.FunctionComponent<PricingProps> = ({
  title,
  value,
  unit,
}) => (
  <PricingGrid>
    <div style={{ paddingRight: 12 }}>
      <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD} lineHeight={16}>
        {title}
      </NotoSansTypography>
    </div>
    <MontserratTypography
      fontSize={18}
      weight={FONTS.MONTSERRAT.SEMI_BOLD}
      lineHeight={18}
    >
      {(value || 0).toLocaleString()}
    </MontserratTypography>
    <div style={{ paddingLeft: 2, paddingTop: 2 }}>
      <NotoSansTypography fontWeight={FONTS.NOTO_SANS.MEDIUM} lineHeight={15}>
        {unit}
      </NotoSansTypography>
    </div>
  </PricingGrid>
);

interface OrderList {
  edges: ConciergeOrderEdge[];
  pageInfo: UseInputState;
}

const DashboardContainer: React.FunctionComponent = () => {
  const [dashboardOption, setDashboardOption] =
    useRecoilState(dashboardOptionState);
  const { accommodationId } = useGetAccommodationId();
  const { pagination, setPagination } = usePagination();
  const { search } = useLocation();

  const queries = Object.fromEntries(new URLSearchParams(search));

  const [modal, setModal] = React.useState<Modal>({
    isOpened: false,
    type: "",
    data: null,
  });

  const statusMaker = React.useMemo(() => {
    const type = queries?.type;

    switch (type) {
      case "WAITING":
        return [ConciergeOrderStatus.Waiting];
      case "PROCESSING":
        return [ConciergeOrderStatus.Processing, ConciergeOrderStatus.Delivery];
      case "FINISH":
        return [
          ConciergeOrderStatus.Finish,
          ConciergeOrderStatus.AdminCancel,
          ConciergeOrderStatus.UserCancel,
        ];
      default:
        return [ConciergeOrderStatus.Waiting];
    }
  }, [search]);

  const [options, setOptions] = React.useState<SearchOptions>({
    status: statusMaker as [DashboardType],
    startDate: dashboardOption.startDate,
    endDate: dashboardOption.endDate,
    phone: null,
  });

  const [statics, setStatics] = React.useState<ConciergeOrderStatistics>({
    WAITING: 0,
    PROCESSING: 0,
    DELIVERY: 0,
    FINISH: 0,
    ADMIN_CANCEL: 0,
    USER_CANCEL: 0,
    amount: 0,
  });

  const { data: conciergeOrders, refetch: refetchConciergeOrders } = useQuery(
    getConciergeOrdersQuery,
    {
      variables: {
        accommodationId,
        options: {
          ...options,
          status: statusMaker as [DashboardType],
        },
        connectionInput: {
          page: pagination.page,
          limit: pagination.limit,
        },
      },
      onCompleted: (data) => {
        setPagination({
          ...data?.getConciergeOrders?.pageInfo,
          inital: false,
        });
      },
    }
  );

  const orderList = useMemo(
    () => conciergeOrders?.getConciergeOrders,
    [conciergeOrders]
  );

  const [getConciergeOrderStatistics] = useLazyQuery(
    getConciergeOrderStatisticsQuery
  );
  const [emitUpdateConciergeOrderMutation] = useMutation(
    UpdateConciergeOrderMutation
  );

  const getConciergeStatics = async () => {
    const { data } = await getConciergeOrderStatistics({
      variables: {
        accommodationId,
        options: {
          startDate: options.startDate,
          endDate: options.endDate,
        },
      },
    });

    if (data?.getConciergeOrderStatistics) {
      setStatics(data?.getConciergeOrderStatistics);
    }
  };

  const refetch = useCallback(() => {
    setDashboardOption({
      startDate: options.startDate,
      endDate: options.endDate,
    });
    getConciergeStatics();
    refetchConciergeOrders();
  }, [options, dashboardOption]);

  useEffect(() => {
    window.refetchOrderData = refetchConciergeOrders;
  }, [refetchConciergeOrders]);

  const updateConciergeOrder = async (
    status: ConciergeOrderStatus,
    id: string
  ) => {
    let statusMessage = "배달시작";

    if (status === ConciergeOrderStatus.AdminCancel) {
      statusMessage = "배달중지";
    } else if (status === ConciergeOrderStatus.Finish) {
      statusMessage = "배달완료";
    }

    await toast.promise(
      emitUpdateConciergeOrderMutation({
        variables: {
          accommodationId,
          input: {
            id,
            status,
          },
        },
        onCompleted: (result) => {},
      }),
      {
        loading: (
          <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>
            변경중...
          </NotoSansTypography>
        ),
        success: (
          <NotoSansTypography
            lineHeight={30}
            fontSize={20}
            fontWeight={FONTS.NOTO_SANS.BOLD}
          >{`상태변경(${statusMessage})이 성공적으로 완료되었습니다.`}</NotoSansTypography>
        ),
        error: (
          <NotoSansTypography
            lineHeight={30}
            fontSize={20}
            fontWeight={FONTS.NOTO_SANS.BOLD}
          >
            상태변경 처리 중 문제가 발생했습니다.
          </NotoSansTypography>
        ),
      }
    );
  };

  const onDelivery = (data: ConciergeOrder, status: ConciergeOrderStatus) => {
    updateConciergeOrder(status, data.id);
  };

  const onProcessing = (
    data: ConciergeOrder,
    status?: ConciergeOrderStatus
  ) => {
    updateConciergeOrder(status || ConciergeOrderStatus.Delivery, data.id);
  };

  const onConfirm = (data: ConciergeOrder, status?: ConciergeOrderStatus) => {
    if (data.status === ConciergeOrderStatus.Waiting) {
      setModal({
        type: "order",
        isOpened: true,
        data,
      });
    } else if (data.status === ConciergeOrderStatus.Processing) {
      onProcessing(data, status);
    } else if (data.status === ConciergeOrderStatus.Delivery) {
      if (status) {
        onDelivery(data, status);
      }
    }
  };

  const onCloseModal = React.useCallback(() => {
    setModal({
      isOpened: false,
      type: "",
      data: null,
    });
  }, []);

  const onShowRejectModal = React.useCallback((data: ConciergeOrder) => {
    setModal({
      type: "orderReject",
      isOpened: true,
      data,
    });
  }, []);

  React.useEffect(() => {
    if (search) {
      let type: string = queries?.type;

      if (type !== "PROCESSING" && type !== "WAITING" && type !== "FINISH") {
        type = "WAITING";
      }
    }
  }, [search]);

  React.useLayoutEffect(() => {
    if (queries.type) {
      setOptions({
        ...options,
        status: statusMaker as [DashboardType],
        // startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        // endDate: moment().format('YYYY-MM-DD'),
        phone: null,
      });
    }
  }, [search]);

  React.useEffect(() => {
    setPagination(usePaginationInitialConfig);
  }, [options]);

  return (
    <Wrapper>
      <PricingWrapper>
        <Grid>
          <Pricing title="총 주문금액" value={statics.amount} unit="원" />
          <Pricing
            title="총 주문건수"
            value={
              statics.WAITING +
              statics.PROCESSING +
              statics.DELIVERY +
              statics.FINISH +
              statics.USER_CANCEL +
              statics.ADMIN_CANCEL
            }
            unit="건"
          />
        </Grid>
        <Grid>
          <Pricing title="접수대기" value={statics.WAITING} unit="건" />
          <Pricing title="처리중" value={statics.PROCESSING} unit="건" />
        </Grid>
        <Grid>
          <Pricing title="배달중" value={statics.DELIVERY} unit="건" />
          <Pricing title="배달완료" value={statics.FINISH} unit="건" />
        </Grid>
        <Grid isLast>
          <Pricing title="고객취소" value={statics.USER_CANCEL} unit="건" />
          <Pricing title="주문거절" value={statics.ADMIN_CANCEL} unit="건" />
        </Grid>
      </PricingWrapper>
      <DashboardFilter options={options} setOptions={setOptions} />
      <div style={{ paddingBottom: 120 }}>
        {orderList?.edges?.map((data: ConciergeOrderEdge) => (
          <DashboardItem
            data={data.node}
            key={data.node.id}
            onReject={onShowRejectModal}
            onConfirm={onConfirm}
          />
        ))}
        {!orderList?.pageInfo?.totalCount || (
          <Pagination
            paginationInfo={pagination}
            setCurrentPage={setPagination}
          />
        )}
        {!orderList?.edges.length && (
          <DataNotFound text="주문내역이 없습니다." />
        )}
      </div>
      <>
        {modal.type === "order" && modal.data && (
          <DashboardOrderModal
            isOpened={modal.isOpened}
            data={modal.data}
            refetch={refetch}
            onClose={onCloseModal}
            onReject={onShowRejectModal}
          />
        )}
        {modal.type === "orderReject" && modal.data && (
          <DashboardOrderReject
            isOpened={modal.isOpened}
            data={modal.data}
            refetch={refetch}
            onClose={onCloseModal}
          />
        )}
      </>
    </Wrapper>
  );
};

export default DashboardContainer;
