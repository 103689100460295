import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  InMemoryCache,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { API_HOST, WS_API_HOST } from './constants';
import { authHandler } from './authHandler';

export const apolloCache = new InMemoryCache();

const errorLink = onError(
  ({
    graphQLErrors,
    networkError,
    operation,
    forward,
    // eslint-disable-next-line consistent-return
  }) => {
    if (graphQLErrors) {
      let needRefreshToken: boolean = false;
      let needRefreshSubscription: boolean = false;

      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );

        needRefreshToken =
          typeof path === 'string' && message.includes('401 Access denied');
        if (
          needRefreshToken &&
          /subscription/i.test(path as unknown as string)
        ) {
          needRefreshSubscription = true;
        }
      });

      if (needRefreshToken) {
        return (
          fromPromise(authHandler.refreshAccessToken())
            .filter((value) => Boolean(value))
            // @ts-ignore
            .flatMap(() => {
              if (needRefreshSubscription) {
                console.log(
                  'Refreshing token due to subscription authorize error.',
                );
                window.location.reload();
              } else {
                forward(operation);
              }
            })
        );
      }

      authHandler.refreshAccessToken();
    }
    if (networkError) {
      if (
        (networkError as any).result?.message === 'jwt expired' ||
        /Token verification failed/.test((networkError as any).result?.message)
      ) {
        return fromPromise(authHandler.refreshAccessToken())
          .filter((value) => Boolean(value))
          .flatMap(() => forward(operation));
      }
      console.log('[Network error]: ', networkError);
    }
  },
);

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((oper) => {
          const token = authHandler.accessToken;
          if (token) {
            oper.setContext({
              headers: {
                Authorization: `Bearer ${token}` || null,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const wsLink = new WebSocketLink({
  uri: `${WS_API_HOST}/graphql`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      Authorization: `Bearer ${authHandler.accessToken}`,
    }),
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  createUploadLink({
    uri: `${API_HOST}/graphql`,
  }) as unknown as ApolloLink,
);

export default new ApolloClient({
  uri: API_HOST,
  link: ApolloLink.from([errorLink, requestLink, splitLink]),
  cache: apolloCache,
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
});
