import React from "react";
import Modal from "components/ui/Modal";
import XClose from "components/ui/Close";
import styled from "styled-components";
import { COLORS, FONTS } from "@common/styles";
import { Button } from "components/ui/Button";
import { ReactComponent as PaymentIcon } from "images/ico_mobile_pay.svg";
import { ReactComponent as PaymentOnSiteIcon } from "images/ico_on_site_payment.svg";
import { ReactComponent as BrownCheckBox } from "images/ico_brown_check.svg";
import { ReactComponent as BlackCheckBox } from "images/ico_black_check.svg";
import { ReactComponent as ExclamationMark } from "images/exclamationmark.svg";
import { NotoSansTypography } from "@common/components";
import { useLazyQuery, useMutation } from "@apollo/client";
import { setConciergeServiceSettingsMutation } from "graphql/settings";
import { getMyAccommodationsQuery } from "graphql/accommodations";
import toast from "react-hot-toast";
import { useGetAccommodationId } from "helpers/hooks";

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

interface ItemProps {
  title: string;
  icon: React.ReactElement;
  description?: string;
}

interface CheckBoxProps {
  text: string;
  marginRight?: number;
  isSelected: boolean;
  onClick: () => void;
}

const Wrapper = styled.div`
  width: 560px;
  height: 550px;
  border-radius: 16px;
  padding: 40px 72px;
`;

const Grid = styled.div`
  display: flex;
  align-items: center;
`;

const ItemWrapper = styled.div`
  margin-top: 40px;
`;

const CheckBoxButton: React.FunctionComponent<CheckBoxProps> = ({
  text,
  marginRight,
  isSelected,
  onClick,
}) => (
  <Button
    text={text}
    width="198px"
    height={64}
    icon={isSelected ? <BrownCheckBox /> : <BlackCheckBox />}
    styleProps={(marginRight && { marginRight }) || undefined}
    fontColor={(isSelected && COLORS.BROWN) || undefined}
    fontWeight="bold"
    borderColor={(isSelected && COLORS.BROWN) || undefined}
    onClick={onClick}
  />
);

const Item: React.FunctionComponent<ItemProps> = ({
  title,
  icon,
  description,
}) => (
  <ItemWrapper>
    <Grid>
      <div style={{ marginRight: 8 }}>{icon}</div>
      <NotoSansTypography
        fontSize={18}
        fontWeight={FONTS.NOTO_SANS.BOLD}
        letterSpacing={-0.54}
      >
        {title}
      </NotoSansTypography>
    </Grid>
    {description && (
      <div style={{ margin: "22px 0px" }}>
        <NotoSansTypography fontColor={COLORS.GRAY_01} lineHeight={null}>
          {description}
        </NotoSansTypography>
      </div>
    )}
  </ItemWrapper>
);

type PaymentType = "payment" | "card";
interface PaymentState {
  payment: boolean;
  card: boolean;
}

const PaymentOptionModal: React.FunctionComponent<Props> = ({
  isOpened,
  onClose,
}) => {
  const { accommodationId } = useGetAccommodationId();

  const [selectedType, setSelectedType] = React.useState<PaymentState>({
    payment: false,
    card: false,
  });

  const [emitSetConciergeServiceSettingsMutation] = useMutation(
    setConciergeServiceSettingsMutation
  );
  const [getMyAccommodations] = useLazyQuery(getMyAccommodationsQuery);

  const getSettings = async () => {
    const response = await getMyAccommodations();

    if (response.data.getMyAccommodations.length) {
      const { onSitePaymentByCard, onSitePaymentByCash } =
        response.data.getMyAccommodations[0].conciergeServiceSettings;

      setSelectedType({
        payment: onSitePaymentByCash,
        card: onSitePaymentByCard,
      });
    }
  };

  React.useEffect(() => {
    getSettings();
  }, []);

  const onSave = async () => {
    await toast.promise(
      emitSetConciergeServiceSettingsMutation({
        variables: {
          accommodationId,
          inputs: [
            {
              key: "onSitePaymentByCash",
              value: String(selectedType.payment),
            },
            {
              key: "onSitePaymentByCard",
              value: String(selectedType.card),
            },
          ],
        },
      }),
      {
        loading: (
          <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>
            변경중...
          </NotoSansTypography>
        ),
        success: (
          <NotoSansTypography
            lineHeight={30}
            fontSize={20}
            fontWeight={FONTS.NOTO_SANS.BOLD}
          >
            결제방식 옵션 변경이 성공적으로 완료되었습니다.
          </NotoSansTypography>
        ),
        error: (
          <NotoSansTypography
            lineHeight={30}
            fontSize={20}
            fontWeight={FONTS.NOTO_SANS.BOLD}
          >
            결제방식 옵션 변경 중 문제가 발생했습니다.
          </NotoSansTypography>
        ),
      }
    );
  };

  const onSelectType = React.useCallback((type: PaymentType) => {
    setSelectedType((initialType: PaymentState) => ({
      ...initialType,
      [type]: !initialType[type],
    }));
  }, []);

  return (
    <Modal isOpen={isOpened} onClose={onClose}>
      <Wrapper>
        <Grid style={{ justifyContent: "center" }}>
          <div style={{ paddingLeft: 16 }}>
            <NotoSansTypography
              fontSize={32}
              lineHeight={32}
              letterSpacing={-0.96}
              fontWeight={FONTS.NOTO_SANS.BOLD}
              fontColor={COLORS.ACTIVE_BLACK}
            >
              결제방식 옵션
            </NotoSansTypography>
          </div>
          <XClose containerStyles={{ right: -130 }} onClick={onClose} />
        </Grid>
        <div style={{ marginTop: 16 }}>
          <NotoSansTypography
            fontColor={COLORS.GRAY_01}
            textAlign="center"
            lineHeight={28}
          >
            이 결제방식 옵션은 객실로 룸서비스 배달시,
            {"\n"}
            지불할 결제방식을 선택하는 옵션입니다.
          </NotoSansTypography>
        </div>
        <div>
          {/* <div style={{ borderBottom: `1px solid ${COLORS.GRAY_02}`, paddingBottom: 40 }}>
            <Item title="모바일 결제" icon={<PaymentIcon />} />
            <Button text="선결제 (필수)" width="414px" height={64} fontWeight="bold" styleProps={{ marginTop: 22 }} />
          </div> */}
          <Item
            title="현장 선불 결제"
            icon={<PaymentOnSiteIcon />}
            description="룸서비스 상품을 고객에게 전달할 때 고객에게 바로 결제 받는 수단을 의미합니다."
          />
          <Grid>
            <CheckBoxButton
              text="현금 결제"
              marginRight={18}
              isSelected={selectedType.payment}
              onClick={() => onSelectType("payment")}
            />
            <CheckBoxButton
              text="카드 결제"
              isSelected={selectedType.card}
              onClick={() => onSelectType("card")}
            />
          </Grid>
        </div>
        <Grid
          style={{ alignItems: "flex-start", marginTop: 24, marginBottom: 24 }}
        >
          <div style={{ marginRight: 10, marginTop: 2 }}>
            <ExclamationMark />
          </div>
          <NotoSansTypography fontColor={COLORS.PINK_RED} lineHeight={null}>
            두 옵션을 모두 선택하지 않으시면 모바일 선결제만
            {"\n"}
            가능하게 적용됨을 참고부탁드립니다.
          </NotoSansTypography>
        </Grid>
        <Grid>
          <Button
            text="저장"
            backgroundColor={COLORS.BROWN}
            borderWidth="0"
            fontColor={COLORS.WHITE}
            width="414px"
            onClick={onSave}
          />
        </Grid>
      </Wrapper>
    </Modal>
  );
};

export default PaymentOptionModal;
