import { useSubscription } from "@apollo/client";
import { CONCIERGE_SUBSCRIPTION } from "graphql/notification.ws";
import apolloClient from "helpers/apolloClient";
import { CONCIERGE_SUBSCRIPTION_TYPE } from "helpers/constants";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { dashboardOptionState } from "recoil/dashboardOptionState";

const DataUpdater = ({ accommodationId }: { accommodationId: string }) => {
  const { data } = useSubscription(CONCIERGE_SUBSCRIPTION, {
    variables: {
      accommodationId,
    },
    fetchPolicy: "no-cache",
  });

  const dashboardOptions = useRecoilValue(dashboardOptionState);

  useEffect(() => {
    if (data) {
      const subscription: { data: string; type: string } =
        data.conciergeSubscription;

      if (
        subscription.type ===
        CONCIERGE_SUBSCRIPTION_TYPE.CONCIERGE_ORDER_UPDATED
      ) {
        apolloClient.refetchQueries({
          updateCache: (cache) => {
            cache.evict({
              fieldName: "getConciergeOrderStatistics",
              args: {
                accommodationId,
                options: {
                  startDate: dashboardOptions.startDate,
                  endDate: dashboardOptions.endDate,
                },
              },
            });
          },
        });
        window?.refetchOrderData();
      }
    }
  }, [data, accommodationId, dashboardOptions]);

  return <></>;
};

export default DataUpdater;
