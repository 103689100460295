import { gql } from '@apollo/client';

export const CONCIERGE_SUBSCRIPTION = gql`
    subscription Subscription($accommodationId: ID!) {
      conciergeSubscription(accommodationId: $accommodationId) {
        type
        data
      }
    }
`;
